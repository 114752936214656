import React from "react"
import { Box, Heading, Link, Text, themeGet } from "@fuegokit/react"
import styled from "styled-components"

import { Head, Header } from "@fuegokit/gatsby-theme-fuegodocs"

import Layout from "../components/Layout"
import Footer from "../components/Footer"
import { ArticlePageWrapper } from "../components"

const NotFoundPage = () => (
  <Box>
    <Header packageName="website" />
    <Head title="404: Not found" />
    <NotFoundPageWrapper>
      <NotFoundHeading>404: Page not found</NotFoundHeading>
      <NotFoundText>
        Return to <Link href="https://fuegokit.design">our home page</Link> or
        send a message in{" "}
        <Link
          target="_blank"
          href="https://appfireworkspace.slack.com/archives/C03CTJZ4BUH"
        >
          #design-systems channel
        </Link>{" "}
        if you can’t find what you’re looking for.
      </NotFoundText>
    </NotFoundPageWrapper>
  </Box>
)

const NotFoundHeading = styled(Heading)`
  font: ${themeGet("font.heading.xlarge")};
`

const NotFoundText = styled(Text)`
  font: ${themeGet("font.body.large")};
  margin: ${themeGet("space.1")} 0;
`

const NotFoundPageWrapper = styled(Box)`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: ${themeGet("space.2")};
  margin: ${themeGet("space.8")} auto;
  border: 1px solid ${themeGet("color.border.warning.default")};
  max-width: 720px;
  border-radius: ${themeGet("radii.3")};
  padding: ${themeGet("space.8")};
  height: 100%;

  @media (max-width: ${themeGet("breakpoints.2")}) {
    margin: ${themeGet("space.8")} ${themeGet("space.4")};
    padding: ${themeGet("space.4")};
  }
`

export default NotFoundPage
